
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../../components/layout.component";
import { useForm, SubmitHandler } from "react-hook-form";
import ProductModel from "../../models/product/product-model";
import productHttpService from "../../http-services/product-http-service";
import { CategoryModel } from "../../models/category/category.model";
import JsonClientMessageStatuses from "../../commons/enums/enum.json-client-message";
import { Zoom, toast } from "react-toastify";
import { EnumProductTypes } from "../../commons/enums/enum-product";

function SaveProductComponent() {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [categories, setCategories] = useState<CategoryModel[] | null>(null);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useForm<ProductModel>({
        defaultValues: {}
    })

    const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
    };


    const onSubmit = async (data: any) => {
        let formData = new FormData();
        formData.append('Type', `${EnumProductTypes.Product}`);

        for (let i = 0; i < selectedFiles!.length; i++) {
            formData.append('productImages', selectedFiles![i]);
        }

        for (let key in data) {
            formData.append(key, data[key]);
        }

        let result = await productHttpService.saveProduct(formData);
        if (result && result.status === JsonClientMessageStatuses.Success) {
            reset();
            toast.success(`Sản phẩm đã được lưu trong hệ thống.`,
                {
                    autoClose: 3000, transition: Zoom, onClick: (e) => {
                        navigate("/san-pham");
                    },
                    onClose: (e) => {
                        navigate("/san-pham");
                    }
                });
        } else {
            toast.warning(`Opps không thể đặt đơn vào lúc này, khách yêu vui lòng thử lại sau nhé! ${result.message}`, {
                transition: Zoom
            });
        }
    }

    useEffect(() => {
        const fetch = async () => {
            let result = await productHttpService.getDataToCreateProduct();
            setValue("code", result.data.productCode);
            setCategories(result.data.categories);
        }
        fetch();
    }, []);

    return (
        <LayoutComponent>
            <div id="page-wrapper">
                <div className="main-page">
                    <ol className="breadcrumb">
                        <li><Link to="/">Trang chủ</Link></li>
                        <li><Link to="/san-pham">Sản phẩm</Link></li>
                        <li className="active">Tạo sản phẩm</li>
                    </ol>

                    <div className="bs-example widget-shadow" data-example-id="hoverable-table">
                        <div className="form-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label >Tên sản phẩm</label>
                                    <input {...register('name', { required: true })} type="text" className="form-control" />
                                    {errors.name && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label >Danh mục</label>
                                    <select {...register('categoryId', { required: true })} className="form-control">
                                        <option value="">---Chọn---</option>
                                        {categories?.map(c => <option key={`save-product--category-${c.id}`} value={c.id}>{c.name}</option>)}
                                    </select>
                                    {errors.categoryId && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label >Mã sản phẩm</label>
                                    <input {...register('code', { required: true })} type="text" className="form-control" />
                                    {errors.code && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label >Nhóm sản phẩm</label>
                                    <input {...register('groupCode', { required: false })} type="text" className="form-control" />
                                </div>

                                <div className="form-group">
                                    <label >Mô tả</label>
                                    <textarea {...register('description', { required: false })} className="form-control"></textarea>
                                </div>

                                <div className="form-group">
                                    <label >Giá gốc</label>
                                    <input  {...register('price', { required: true })} type="number" className="form-control" />
                                    {errors.price && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label >Giá đã giảm</label>
                                    <input  {...register('discount', { required: true })} type="number" className="form-control" />
                                    {errors.discount && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="exampleInputFile">File input</label>
                                    <input type="file" accept="image/*" multiple onChange={selectFiles} />
                                </div>

                                <button type="submit" className="btn btn-default">Tạo</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}

export default SaveProductComponent;