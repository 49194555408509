
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../../components/layout.component";
import { useForm, SubmitHandler } from "react-hook-form";
import ProductModel from "../../models/product/product-model";
import productHttpService from "../../http-services/product-http-service";
import { CategoryModel } from "../../models/category/category.model";
import JsonClientMessageStatuses from "../../commons/enums/enum.json-client-message";
import { Zoom, toast } from "react-toastify";
import categoryHttpService from "../../http-services/category-http-service";

function SaveCategoryComponent() {
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useForm<CategoryModel>({
        defaultValues: {}
    })

    const selectFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
    };


    const onSubmit = async (data: any) => {
        delete data.icon;
        console.log(data);
        let formData = new FormData();

        if (selectFiles && selectFiles?.length > 0) {
            for (let i = 0; i < selectedFiles!.length; i++) {
                formData.append('icon', selectedFiles![i]);
            }
        }

        for (let key in data) {
            formData.append(key, data[key]);
        }

        let result = await categoryHttpService.saveCategory(formData);
        if (result && result.status === JsonClientMessageStatuses.Success) {
            reset();
            toast.success(`Danh mục đã được lưu trong hệ thống.`,
                {
                    autoClose: 3000, transition: Zoom, onClick: (e) => {
                        navigate("/san-pham");
                    },
                    onClose: (e) => {
                        navigate("/san-pham");
                    }
                });
        } else {
            toast.warning(`Opps, chưa thể lưu danh mục vào lúc này, vui lòng thử lại sau. ${result.message}`, {
                transition: Zoom
            });
        }
    }

    useEffect(() => {
    }, []);

    return (
        <LayoutComponent>
            <div id="page-wrapper">
                <div className="main-page">
                    <ol className="breadcrumb">
                        <li><Link to="/">Trang chủ</Link></li>
                        <li><Link to="/san-pham">Sản phẩm</Link></li>
                        <li className="active">Tạo sản phẩm</li>
                    </ol>

                    <div className="bs-example widget-shadow" data-example-id="hoverable-table">
                        <div className="form-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label >Tên danh mục</label>
                                    <input {...register('name', { required: true })} type="text" className="form-control" />
                                    {errors.name && <span>Trường này là bắt buộc</span>}
                                </div>

                                <div className="form-group">
                                    <label>File input</label>
                                    <input type="file" accept="image/*" multiple onChange={selectFiles} />
                                </div>

                                <button type="submit" className="btn btn-default">Tạo</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}

export default SaveCategoryComponent;