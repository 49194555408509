import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../../components/layout.component";
function DashboardComponent() {
    return (
        <LayoutComponent>
            <div id="page-wrapper">
                <div className="main-page">
                    <h2 className="title1">Blank Page</h2>
                    <div className="blank-page widget-shadow scroll" id="style-2 div1">
                        <p>Under construction
                        </p>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}

export default DashboardComponent;

