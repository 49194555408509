import React, { ReactNode } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderComponent from "./header.component";

interface LayoutProps {
    children: ReactNode;
}

function LayoutComponent(props: LayoutProps) {
    return (
        <div className="main-content">
            <HeaderComponent />
            {props.children}
            <div className="footer">
                <p>&copy; 2018 Glance Design Dashboard. All Rights Reserved | Design by <a href="https://w3layouts.com/"
                    target="_blank">w3layouts</a></p>
            </div>

            <ToastContainer />
        </div>
    );
}

export default LayoutComponent;