import React from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardComponent from './containers/dashboard';
import CategoryComponent from './containers/category';
import OrderComponent from './containers/order';
import ProductComponent from './containers/product';
import SaveProductComponent from './containers/product/save';
import SaveCategoryComponent from './containers/category/save';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardComponent />} />

        <Route path="/danh-muc" element={<CategoryComponent />} />

        <Route path="/danh-muc/tao-danh-muc" element={<SaveCategoryComponent />} />

        <Route path="/danh-muc/sua-danh-muc/{id}" element={<SaveCategoryComponent />} />

        <Route path="/san-pham" element={<ProductComponent />} />

        <Route path="/san-pham/tao-san-pham" element={<SaveProductComponent />} />

        <Route path="/san-pham/sua-san-pham/{id}" element={<SaveProductComponent />} />

        <Route path="/don-hang" element={<OrderComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
