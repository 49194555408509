import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../../components/layout.component";
function CategoryComponent() {
    const navigate = useNavigate();

    const onCreateCategory = () => {
        navigate('/danh-muc/tao-danh-muc');
    }

    return (
        <LayoutComponent>
            <div id="page-wrapper">
                <div className="main-page">
                    <ol className="breadcrumb">
                        <li><Link to="/">Trang chủ</Link></li>
                        <li className="active">Danh mục</li>
                    </ol>

                    <div className="text-right">
                        <button onClick={onCreateCategory} type="button" className="btn btn-primary">Thêm danh mục<span className="fa fa-plus"></span></button>
                    </div>

                    <div className="form-body">
                        <form className="form-inline">
                            <div className="form-group">
                                <input type="text" className="form-control" id="exampleInputName2" placeholder="Tên danh mục" />
                            </div>
                            <div className="form-group">
                                <span style={{ 'display': 'inline-block', 'width': '10px' }}></span>
                            </div>
                            <div className="form-group">
                                <select className="form-control">
                                    <option value="">---Tất cả trạng thái---</option>
                                    <option selected value="1">Đang kinh doanh</option>
                                    <option value="2">Đã ngừng kinh doanh</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <span style={{ 'display': 'inline-block', 'width': '10px' }}></span>
                            </div>
                            <button type="submit" className="btn btn-default">Tìm kiếm</button>
                        </form>
                    </div>

                    <div className="bs-example widget-shadow" data-example-id="hoverable-table">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tên</th>
                                    <th>Ngày tạo</th>
                                    <th>Trạng thái</th>
                                    <th>Hành động</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mỹ Phẩm</td>
                                    <td>22-02-2024</td>
                                    <td>Đang sử dụng/ngừng kinh doanh</td>
                                    <td>
                                        <button type="button" className="btn btn-success">Kích hoạt</button>
                                        <span> </span>
                                        <button type="button" className="btn btn-danger">Vô hiệu</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
}

export default CategoryComponent;

