import React, { useEffect, useRef } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
// import $ from "jquery";

declare var $: any;
declare var classie: {
    toggle: (element: any, className: string) => void;
};

function HeaderComponent() {

    const navigate = useNavigate();
    const ref = useRef<boolean>(true);
    const location = useLocation()

    useEffect(() => {
        function disableOther(button: any) {
            if (button !== 'showLeftPush') {
                classie.toggle(showLeftPush, 'disabled');
            }
        }

        if (ref && ref.current) {
            ref.current = false;
            $('.sidebar-menu').SidebarNav();
            var menuLeft = document.getElementById('cbp-spmenu-s1');
            var showLeftPush = document.getElementById('showLeftPush');
            var body = document.body;

            if (!showLeftPush)
                return;

            showLeftPush.onclick = function () {
                classie.toggle(this, 'active');
                classie.toggle(body, 'cbp-spmenu-push-toright');
                classie.toggle(menuLeft, 'cbp-spmenu-open');
                disableOther('showLeftPush');
            };
        }
    }, [])

    const getActivedLink = (path: string): string => {
        return path === location.pathname ? 'active' : '';
    }

    return (
        <>
            <div className="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left" id="cbp-spmenu-s1">
                <aside className="sidebar-left">
                    <nav className="navbar navbar-inverse">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target=".collapse" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <h1><a className="navbar-brand" href="index.html"><span className="fa fa-area-chart"></span> Tu Linh<span
                                className="dashboard_text">Beauty</span></a></h1>
                        </div>
                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="sidebar-menu">
                                <li className="header">Menu</li>
                                <li className={`treeview ${getActivedLink('/')}`}>
                                    <NavLink to="/">
                                        <i className="fa fa-dashboard"></i> <span>Thống Kê</span>
                                    </NavLink>
                                </li>
                                {/* <li className="treeview">
                                    <a href="#">
                                        <i className="fa fa-laptop"></i>
                                        <span>Components</span>
                                        <i className="fa fa-angle-left pull-right"></i>
                                    </a>
                                    <ul className="treeview-menu">
                                        <li><a href="grids.html"><i className="fa fa-angle-right"></i> Grids</a></li>
                                        <li><a href="media.html"><i className="fa fa-angle-right"></i> Media Css</a></li>
                                    </ul>
                                </li> */}

                                <li className={getActivedLink('/danh-muc')}><Link to="/danh-muc"><i className="fa fa-angle-right text-red"></i> <span>Danh Mục</span></Link></li>

                                <li className={getActivedLink('/san-pham')}><Link to="/san-pham"><i className="fa fa-angle-right text-red"></i> <span>Sản Phẩm</span></Link></li>

                                <li className={getActivedLink('/don-hang')}><Link to="/don-hang"><i className="fa fa-angle-right text-red"></i> <span>Đơn hàng</span></Link></li>
                            </ul>
                        </div>
                    </nav>
                </aside>
            </div>

            <div className="sticky-header header-section ">
                <div className="header-left">
                    <button id="showLeftPush"><i className="fa fa-bars"></i></button>
                    <div className="profile_details_left">
                        <ul className="nofitications-dropdown">
                            <li className="dropdown head-dpdn">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                                    className="fa fa-envelope"></i><span className="badge">4</span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <div className="notification_header">
                                            <h3>You have 3 new messages</h3>
                                        </div>
                                    </li>
                                    <li><a href="#">
                                        <div className="user_img"><img src="images/1.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet</p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li className="odd"><a href="#">
                                        <div className="user_img"><img src="images/4.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet </p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li><a href="#">
                                        <div className="user_img"><img src="images/3.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet </p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li>
                                        <div className="notification_bottom">
                                            <a href="#">See all messages</a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown head-dpdn">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                                    className="fa fa-bell"></i><span className="badge blue">4</span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <div className="notification_header">
                                            <h3>You have 3 new notification</h3>
                                        </div>
                                    </li>
                                    <li><a href="#">
                                        <div className="user_img"><img src="images/4.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet</p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li className="odd"><a href="#">
                                        <div className="user_img"><img src="images/1.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet </p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li><a href="#">
                                        <div className="user_img"><img src="images/3.jpg" alt="" /></div>
                                        <div className="notification_desc">
                                            <p>Lorem ipsum dolor amet </p>
                                            <p><span>1 hour ago</span></p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </a></li>
                                    <li>
                                        <div className="notification_bottom">
                                            <a href="#">See all notifications</a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown head-dpdn">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i
                                    className="fa fa-tasks"></i><span className="badge blue1">8</span></a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <div className="notification_header">
                                            <h3>You have 8 pending task</h3>
                                        </div>
                                    </li>
                                    <li><a href="#">
                                        <div className="task-info">
                                            <span className="task-desc">Database update</span><span
                                                className="percentage">40%</span>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="progress progress-striped active">
                                            <div className="bar yellow" style={{ width: '40%' }}></div>
                                        </div>
                                    </a></li>
                                    <li><a href="#">
                                        <div className="task-info">
                                            <span className="task-desc">Dashboard done</span><span
                                                className="percentage">90%</span>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="progress progress-striped active">
                                            <div className="bar green" style={{ width: '90%' }}></div>
                                        </div>
                                    </a></li>
                                    <li><a href="#">
                                        <div className="task-info">
                                            <span className="task-desc">Mobile App</span><span className="percentage">33%</span>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="progress progress-striped active">
                                            <div className="bar red" style={{ width: '33%' }}></div>
                                        </div>
                                    </a></li>
                                    <li><a href="#">
                                        <div className="task-info">
                                            <span className="task-desc">Issues fixed</span><span
                                                className="percentage">80%</span>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="progress progress-striped active">
                                            <div className="bar  blue" style={{ width: '80%' }}></div>
                                        </div>
                                    </a></li>
                                    <li>
                                        <div className="notification_bottom">
                                            <a href="#">See all pending tasks</a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <div className="clearfix"> </div>
                    </div>
                    <div className="clearfix"> </div>
                </div>
                <div className="header-right">
                    <div className="search-box">
                        <form className="input">
                            <input className="sb-search-input input__field--madoka" placeholder="Search..." type="search"
                                id="input-31" />
                            <label className="input__label" htmlFor="input-31">
                                <svg className="graphic" width="100%" height="100%" viewBox="0 0 404 77"
                                    preserveAspectRatio="none">
                                    <path d="m0,0l404,0l0,77l-404,0l0,-77z" />
                                </svg>
                            </label>
                        </form>
                    </div>

                    <div className="profile_details">
                        <ul>
                            <li className="dropdown profile_details_drop">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    <div className="profile_img">
                                        <span className="prfil-img"><img src="images/2.jpg" alt="" /> </span>
                                        <div className="user-name">
                                            <p>Admin Name</p>
                                            <span>Administrator</span>
                                        </div>
                                        <i className="fa fa-angle-down lnr"></i>
                                        <i className="fa fa-angle-up lnr"></i>
                                        <div className="clearfix"></div>
                                    </div>
                                </a>
                                <ul className="dropdown-menu drp-mnu">
                                    <li> <a href="#"><i className="fa fa-cog"></i> Settings</a> </li>
                                    <li> <a href="#"><i className="fa fa-user"></i> My Account</a> </li>
                                    <li> <a href="#"><i className="fa fa-suitcase"></i> Profile</a> </li>
                                    <li> <a href="#"><i className="fa fa-sign-out"></i> Logout</a> </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"> </div>
                </div>
                <div className="clearfix"> </div>
            </div>
        </>
    )
}

export default HeaderComponent;