import JsonClientMessage from "../models/http/json-client-message.order";
import { http } from "./http"

const saveCategory = async (form: FormData): Promise<JsonClientMessage<any>> => {
    return http.postFile('admin/category/save-update', form);
}

const categoryHttpService = {
    saveCategory
};

export default categoryHttpService;