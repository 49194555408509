import JsonClientMessage from "../models/http/json-client-message.order";
import DataForPreparingProduct from "../models/product/data-for-preparing-product";
import ProductModel from "../models/product/product-model";
import { http } from "./http"

const saveProduct = async (formData: FormData): Promise<JsonClientMessage<boolean>> => {
    return http.postFile(`admin/product/save-product`, formData);
}

const getDataToCreateProduct = (): Promise<JsonClientMessage<DataForPreparingProduct>> =>{
    return http.get('admin/product/get-data-to-create-product');
}


const productHttpService = {
    saveProduct,
    getDataToCreateProduct
};

export default productHttpService;